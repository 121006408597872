<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="110px">
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>


    <el-table :data="dataList">
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="订单号" align="center" prop="orderId"/>
      <el-table-column label="订单类型" align="center" prop="orderTypeDesc"/>
      <el-table-column label="交易类型" align="center" prop="tradeTypeDesc"/>
      <el-table-column label="订单状态" align="center" prop="orderStatusDesc"/>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.size"
        @pagination="getList"
    />
  </div>
</template>

<script>
import * as doudianApi from "@/api/crm/doudian";

export default {
  name: "ProductPage",
  data() {
    return {
      dataList: [],
      total: 0,
      queryParams: {
        page: 1,
        size: 10
      }
    }
  },
  created() {

  },
  methods: {
    getList() {
      doudianApi.productList(this.queryParams).then(res => {
        console.log(res)
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams = {page: 1, size: 10}
      this.resetForm("queryForm");
      this.handleQuery();
    }
  }
}
</script>

<style scoped>

</style>