<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="110px">
      <el-form-item label="订单下单时间">
        <el-date-picker v-model="createTime" size="mini" type="datetimerange" range-separator="至"
                        value-format="timestamp" :default-time="['00:00:00', '23:59:59']"
                        start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="订单更新时间">
        <el-date-picker v-model="updateTime" size="mini" type="datetimerange" range-separator="至"
                        value-format="timestamp" :default-time="['00:00:00', '23:59:59']"
                        start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>

      <el-form-item label="订单号">
        <el-input v-model="queryParams.tracking_no" size="mini"></el-input>
      </el-form-item>

      <el-form-item label="商品">
        <el-input v-model="queryParams.product" size="mini"></el-input>
      </el-form-item>

      <el-form-item label="物流单号">
        <el-input v-model="queryParams.trackingNo" size="mini"></el-input>
      </el-form-item>

      <el-form-item label="订单类型">
        <el-select v-model="queryParams.orderType" size="mini">
          <el-option v-for="t in orderTypeList" :value="t.value" :label="t.label"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="售后状态">
        <el-select v-model="queryParams.afterSaleStatusDesc" size="mini">
          <el-option v-for="t in afterSaleStatusDescList" :value="t.value" :label="t.label"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row>
      <el-button type="primary" icon="el-icon-search" size="mini" @click="send">发送短信</el-button>
      <el-button type="primary" icon="el-icon-search" size="mini" @click="SmsPageVisible = true">抖店短信</el-button>
      <el-button type="primary" icon="el-icon-search" size="mini" @click="ProductPageVisible = true">商品列表
      </el-button>
      <el-button type="success" icon="el-icon-upload" size="mini" @click="OrderBindVisible = true">上传绑定数据
      </el-button>
    </el-row>

    <el-table   max-height="600" @selection-change="handleSelectionChange" :data="dataList">
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="订单号" align="center" prop="orderId"/>
      <el-table-column label="商品信息" align="center" prop="productName">
        <template slot-scope="scope">
          <el-tooltip v-for="o of scope.row.skuOrderList" class="item" effect="dark" :content="o.productName"
                      placement="top">
            <div class="text_ell"> {{ o.productName }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="订单来源" align="center" prop="btypeDesc"/>
      <el-table-column label="订单金额" align="center" prop="orderAmount">
        <template slot-scope="scope">
          <span v-text="(scope.row.orderAmount / 100).toFixed(2)"></span>
        </template>
      </el-table-column>
      <el-table-column label="支付金额" align="center" prop="payAmount">
        <template slot-scope="scope">
          <span v-text="(scope.row.payAmount / 100).toFixed(2)"></span>
        </template>
      </el-table-column>
      <el-table-column label="订单类型" align="center" prop="orderTypeDesc"/>
      <el-table-column label="收件人" align="center" prop="maskPostReceiver"/>
      <el-table-column label="收件手机" align="center" prop="maskPostTel"/>
      <el-table-column label="交易类型" align="center" prop="tradeTypeDesc"/>
      <el-table-column label="订单状态" align="center" prop="orderStatusDesc"/>
      <el-table-column label="下单时间" align="center" prop="createTime" :formatter="dateFormat"/>
      <el-table-column label="支付时间" align="center" prop="payTime" :formatter="dateFormat"/>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.size"
        @pagination="getList"
    />
    <el-dialog title="商品列表" width="80%" :modal-append-to-body="false" :visible.sync="ProductPageVisible"
               v-if="ProductPageVisible">
      <ProductPage></ProductPage>
    </el-dialog>

    <el-dialog title="抖店短信" width="80%" :modal-append-to-body="false" :visible.sync="SmsPageVisible"
               v-if="SmsPageVisible">
      <SmsPage></SmsPage>
    </el-dialog>

    <el-dialog title="发送短信" width="80%" :modal-append-to-body="false" :visible.sync="SendSmsVisible"
               v-if="SendSmsVisible">
      <SendSms :ids="ids"></SendSms>
    </el-dialog>

    <el-dialog title="导入绑定数据" width="80%" :modal-append-to-body="false" :visible.sync="OrderBindVisible"
               v-if="OrderBindVisible">
      <OrderBindImport></OrderBindImport>
    </el-dialog>

  </div>
</template>

<script>
import * as doudianApi from "@/api/crm/doudian";
import SmsPage from "./doudian/SmsPage";
import ProductPage from "./doudian/ProductPage";
import SendSms from "@/views/crm/other/doudian/SendSms";
import OrderBindImport from "@/views/crm/other/doudian/OrderBindImport";

export default {
  name: "doudian",
  components: {OrderBindImport, SendSms, SmsPage, ProductPage},
  data() {
    return {
      // 查询参数
      queryParams: {
        page: 1,
        size: 10
      },
      createTime: [],
      updateTime: [],
      total: 0,
      dataList: [],
      SmsPageVisible: false,//抖店显示
      ProductPageVisible: false,//商品列表显示
      SendSmsVisible: false,
      OrderBindVisible: false,
      ids: [],
      afterSaleStatusDescList: [
        {label: '全部', value: 'all'},
        {label: '售后中', value: 'in_aftersale'},
        {label: '退款成功', value: 'refund_success'},
        {label: '退款失败', value: 'refund_fail'},
        {label: '换货成功', value: 'exchange_success'},
        {label: '售后关闭', value: 'aftersale_close'},
      ],
      orderTypeList: [
        {label: '普通订单', value: 0},
        {label: '虚拟商品订单', value: 2},
        {label: '电子券（poi核销）', value: 4},
        {label: '三方核销', value: 5},
      ],
    };
  },
  created() {
    // this.findProductList()
  },
  methods: {
    findProductList() {
      doudianApi.productList({"size": 100, "page": 1}).then(res => {
        if (res.success) {
          this.productList = res.data.data
        }
      })
    },
    handleSelectionChange(val) {
      this.ids = val
    },
    send() {
      this.SendSmsVisible = true
    },
    dateFormat(row, column) {
      var date = row[column.property];
      if (date === undefined || date === 0) {
        return "";
      }
      let dateMat = new Date(date * 1000);
      let y = dateMat.getFullYear();
      let month = dateMat.getMonth() + 1;
      let day = dateMat.getDate();
      let hours = dateMat.getHours();
      let m = dateMat.getMinutes();
      let s = dateMat.getSeconds();
      return `${y}-${month}-${day} ${hours}:${m}:${s}`;
    },
    getList() {
      // if (this.createTime.length == 0 && this.updateTime.length == 0) {
      //   this.$message({type: "error", message: "订单查询时间不可为空！"});
      //   return false;
      // }
      if (this.createTime.length > 0) {
        this.queryParams['createTimeStart'] = this.createTime[0] / 1000
        this.queryParams['createTimeEnd'] = this.createTime[1] / 1000
      }
      if (this.updateTime.length > 0) {
        this.queryParams['updateTimeStart'] = this.updateTime[0] / 1000
        this.queryParams['updateTimeEnd'] = this.updateTime[1] / 1000
      }
      this.queryParams.page = this.queryParams.page - 1
      doudianApi.orderList(this.queryParams).then(res => {
        if (res.success) {
          this.dataList = res.data.shopOrderList
          this.queryParams.page = res.data.page + 1
          this.total = res.data.total
        } else {
          this.$message({type: "error", message: res.subMsg});
        }
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams = {page: 1, size: 10}
      this.resetForm("queryForm");
      this.handleQuery();
    }
  }
}
</script>
<style scoped>
.text_ell {
  width: 10em; /*保证文字不会被半汉字截断,显示10个文字*/
  overflow: hidden; /*超出长度的文字隐藏*/
  text-overflow: ellipsis; /*文字隐藏以后添加省略号*/
  white-space: nowrap; /*强制不换行*/
}
</style>
