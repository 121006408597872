<template>
  <div>
    <el-form :model="taskForm" label-width="120px" label-position="left">
      <el-row>
        <el-col :span="12">
          <el-form-item label="短信签名：" required>
            <el-select v-model="taskForm.sign">
              <el-option v-for="r in signList" :label="r.sign" :value="r.sign"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="短信模板：" required>
            <el-select v-model="taskForm.templateId" @change="changeSms">
              <el-option v-for="r in templateList" style="height: auto;width: 300px" :label="r.templateName"
                         :value="r.templateId">
                <p style="word-break: break-all;white-space: pre-wrap; width: 100%">{{ r.templateContent }}</p>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="短信内容：" required>
              <span v-for="r in templateList" v-if="r.templateId == taskForm.templateId"
                    v-text="r.templateContent"></span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item :label="'变量：'+o" label-width="150px" required v-for="o in options">
            <el-input placeholder="请输入变量值" v-model="sms[`${o}`]" style="width: 300px;margin-right: 20px"
                      class="input-with-select" :ref="`sms_${o}`"></el-input>
            <el-select placeholder="插入参数" v-model="code[`${o}`]" @change="addCode(`${o}`)">
              <el-option v-for="r in codeList" :label="r.label" :value="r.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label-width="0" style="text-align: center" required>
        <el-button type="primary" @click="sendSms">发送短信</el-button>
        <el-button>取 消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import * as doudianApi from "@/api/crm/doudian";
import {batchSendByOrder} from "@/api/crm/doudian";

export default {
  name: "SendSms",
  props: ['ids'],
  data() {
    return {
      taskForm: {},
      codeList: [],
      sms: {},
      codes: "",
      options: [],
      templateList: [],
      signList: [],
      code: {},
    }
  },
  created() {
    this.findTemplate()
    this.findSign()
    this.findHeads()
  },
  methods: {
    findHeads() {
      let orderIds = this.ids.map(e => {
        return e.orderId
      })
      doudianApi.findHeadByIds(orderIds).then(res => {
        if (res.success) {
          for (let e of res.data) {
            this.codeList.push({"label": e, "value": e})
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    sendSms() {
      let p = this.taskForm
      let orderSmsList = []
      let code = JSON.stringify(this.sms)
      p['code'] = code
      for (let t of this.ids) {
        orderSmsList.push({"postTel": t.encryptPostTel, "orderId": t.orderId})
      }
      p['orderSmsList'] = orderSmsList

      console.log(p)

      doudianApi.batchSendByOrder(p).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
          let msg = ''
          for(let r of res.data){
            msg += r + '<br/>'
          }
          this.$notify({
            title: '短信发送成功订单:',
            message: msg,
            dangerouslyUseHTMLString: true,
            duration: 0
          });
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    findTemplate() {
      console.log(this.ids)
      let p = {"size": 50, "page": 0}
      doudianApi.listTemplate(p).then(res => {
        this.templateList = res.data.templateSearchList
      })
    },
    findSign() {
      let p = {"size": 50, "page": 0}
      doudianApi.listSign(p).then(res => {
        this.signList = res.data.signSearchList
      })
    },
    addCode(c) {
      let code = this.code[c]
      this.$set(this.sms, c, '${' + code + '}')
    },
    changeSms(s) {
      if(this.templateList && this.templateList.length > 0){
        for (let t of this.templateList) {
          if (t.templateId === s) {
            var re = /\{(\w+)\}/g;
            let attrs = t.templateContent.match(re)
            this.options = []
            for (let a of attrs) {
              a = a.replace("\{", "").replace("\}", "");
              this.options.push(a)
            }
          }
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
