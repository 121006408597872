<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="110px">
      <el-form-item label="发送时间">
        <el-date-picker v-model="fromTime" size="mini" type="datetimerange" range-separator="至"
                        value-format="timestamp" :default-time="['00:00:00', '23:59:59']"
                        start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row>
      <el-button type="primary" icon="el-icon-search" size="mini" @click="SmsSignVisible = true">短信签名</el-button>
      <el-button type="primary" icon="el-icon-search" size="mini" @click="SmsTempVisible = true">短信模板</el-button>
    </el-row>
    <el-table :data="dataList">
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="消息ID" align="center" prop="messageId"/>
      <el-table-column label="状态" align="center" prop="status"/>
      <el-table-column label="短信内容" align="center" prop="smsContent"/>
      <el-table-column label="发送时间" align="center" prop="sendTime" :formatter="dateFormat"/>
      <el-table-column label="说明" align="center" prop="message"/>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.size"
        @pagination="getList"
    />

    <el-dialog title="短信签名" width="75%" :modal-append-to-body="false" :visible.sync="SmsSignVisible"
               v-if="SmsSignVisible">
      <SmsSign></SmsSign>
    </el-dialog>

    <el-dialog title="短信模板" width="75%" :modal-append-to-body="false" :visible.sync="SmsTempVisible"
               v-if="SmsTempVisible">
      <SmsTemp></SmsTemp>
    </el-dialog>
  </div>
</template>

<script>
import * as doudianApi from "@/api/crm/doudian";
import SmsSign from "./SmsSign";
import SmsTemp from "./SmsTemp";

export default {
  name: "SmsPage",
  components: {SmsSign, SmsTemp},
  data() {
    return {
      dataList: [],
      total: 0,
      queryParams: {
        page: 1,
        size: 10
      },
      SmsTempVisible: false,
      SmsSignVisible: false,
      fromTime: [],
    }
  },
  created() {

  },
  methods: {
    dateFormat(row, column) {
      var date = row[column.property];
      if (date === undefined || date === 0) {
        return "";
      }
      let dateMat = new Date(date * 1000);
      let y = dateMat.getFullYear();
      let month = dateMat.getMonth() + 1;
      let day = dateMat.getDate();
      let hours = dateMat.getHours();
      let m = dateMat.getMinutes();
      let s = dateMat.getSeconds();
      return `${y}-${month}-${day} ${hours}:${m}:${s}`;
    },
    getList() {
      if (this.fromTime.length === 0) {
        this.$message({type: 'error', message: "必须选择时间区间!"});
        return false
      }
      let query = {}
      query.page = this.queryParams.page - 1
      query.size = this.queryParams.size
      query.fromTime = this.fromTime[0] / 1000
      query.toTime = this.fromTime[1] / 1000
      doudianApi.smsList(query).then(res => {
        this.dataList = res.data.smsSendResultList
        this.total = res.data.total
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams = {page: 1, size: 10}
      this.resetForm("queryForm");
      this.handleQuery();
    }
  }
}
</script>

<style scoped>

</style>