<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="110px">
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row>
      <el-button type="primary" icon="el-icon-search" size="mini" @click="addSignVisible = true">申请签名</el-button>
    </el-row>
    <el-table :data="dataList">
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="签名" align="center" prop="sign"/>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.size"
        @pagination="getList"
    />

    <el-dialog :visible.sync="addSignVisible" :modal-append-to-body="false" v-if="addSignVisible">
      <el-form :model="signForm" label-width="80px">
        <el-form-item label="签名">
          <el-input v-model="signForm.sign"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">立即创建</el-button>
          <el-button @click="addSignVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import * as doudianApi from "@/api/crm/doudian";

export default {
  name: "SmsSign",
  data() {
    return {
      dataList: [],
      total: 0,
      queryParams: {
        page: 1,
        size: 10
      },
      addSignVisible: false,
      signForm: {
        sign: null
      },
    }
  },
  created() {

  },
  methods: {
    onSubmit() {
      doudianApi.applySign(this.signForm).then(res => {
        if (res.data.code === 0) {
          this.$message({type: 'success', message: "已提交申请!"});
          this.addSignVisible = false
        } else {
          this.$message({type: 'error', message: res.data.message});
        }
      })
    },
    getList() {
      let query = {}
      query.page = this.queryParams.page - 1
      query.size = this.queryParams.size
      doudianApi.listSign(query).then(res => {
        this.dataList = res.data.signSearchList
        this.total = res.data.total
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams = {page: 1, size: 10}
      this.resetForm("queryForm");
      this.handleQuery();
    }
  }
}
</script>

<style scoped>

</style>