<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="110px">
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row>
      <el-button type="primary" icon="el-icon-search" size="mini" @click="addTempVisible = true">模板提审</el-button>
    </el-row>

    <el-table :data="dataList">
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="模板编号" align="center" prop="templateId"/>
      <el-table-column label="模板名称" align="center" prop="templateName"/>
      <el-table-column label="模板内容" align="center" prop="templateContent"/>
      <el-table-column label="模板类型" align="center" prop="channelType">
        <template slot-scope="scope">
          <span v-for="item of templateTypeList" v-if="scope.row.channelType === item.value"></span>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.size"
        :page-sizes="[5, 10, 15, 20]"
        @pagination="getList"
    />

    <el-dialog :visible.sync="addTempVisible" :modal-append-to-body="false" width="70%" v-if="addTempVisible">
      <el-form :model="tempForm" label-width="80px">
        <el-form-item label="模板名称">
          <el-input v-model="tempForm.templateName"></el-input>
        </el-form-item>
        <el-form-item label="模板内容">
          <el-input type="textarea" :rows="3"
                    placeholder="您购买的商品已重新发出，${name}快递运单号：${number}，关注“XXX”公众号刷新订单获取最新物流信息哦~给您造成不便敬请谅解。"
                    v-model="tempForm.templateContent"></el-input>
        </el-form-item>
        <el-form-item label="模板类型">
          <el-select v-model="tempForm.templateType" placeholder="请选择">
            <el-option
                v-for="item in templateTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">立即创建</el-button>
          <el-button @click="addTempVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import * as doudianApi from "@/api/crm/doudian";

export default {
  name: "SmsTemp",
  data() {
    return {
      dataList: [],
      total: 0,
      queryParams: {
        page: 1,
        size: 5
      },
      tempForm: {},
      addTempVisible: false,
      templateTypeList: [
        {'label': '通知短信', 'value': 'CN_NTC'},
        {'label': '营销短信(可退订)', 'value': 'CN_MKT'},
        {'label': '验证码', 'value': 'CN_OTP'}
      ]
    }
  },
  created() {

  },
  methods: {
    onSubmit() {
      doudianApi.applyTemplate(this.tempForm).then(res => {
        if (res.data.code === 0) {
          this.$message({type: 'success', message: "已提交申请!"});
          this.addTempVisible = false
        } else {
          this.$message({type: 'error', message: res.data.message});
        }
      })
    },
    getList() {
      let query = {}
      query.page = this.queryParams.page - 1
      query.size = this.queryParams.size
      doudianApi.listTemplate(query).then(res => {
        this.dataList = res.data.templateSearchList
        this.total = res.data.total
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams = {page: 1, size: 5}
      this.resetForm("queryForm");
      this.handleQuery();
    }
  }
}
</script>

<style scoped>

</style>