<template>
  <div>
    <el-upload
        class="upload-demo"
        ref="upload"
        :headers="headers"
        :action="upload.url"
        :on-success="handleSuccess"
        :on-error="handleError"
        :file-list="fileList"
        :auto-upload="false">
      <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
      <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>
      <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件</div>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: "OrderBindImport",
  data() {
    return {
        headers:{"version": localStorage.getItem("_version"),},
      upload: {
        open: false,
        title: "",
        isUploading: false,
        url: `/api/manage/doudian/order/bind/importOrderBind`
      },
      fileList: [],
    }
  },
  methods: {
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleSuccess(res) {
      if (res.success) {
        this.$message({type: "success", message: res.msg});
      } else {
        this.$message({type: "error", message: res.msg});
      }
    },
    handleError(res) {
      this.$message({type: "error", message: "文件上传失败"});
    }
  }
}
</script>

<style scoped>

</style>